import * as React from "react";

import { Helmet } from "react-helmet";

import PageLayout from "../components/PageLayout";
import HeroBanner from "../components/HeroBanner";
import Module from "../components/Module";
import PlainModule from "../components/PlainModule";

import MudpoolsImage from "../images/iStock-849704532.jpg";
import VaccineTubesImage from "../images/iStock-1297582177-crop.jpg";
import TechnicianImage from "../images/iStock-628490438.jpg";

import { Button } from "react-bootstrap";

const AboutPage = () => {
  return (
    <PageLayout>
      <Helmet htmlAttributes={{
        lang: 'en',
      }}>
        <title>About Tayos Corp</title>
      </Helmet>

      <HeroBanner backgroundImage={MudpoolsImage} backgroundShift="15%" />

      <PlainModule variant="left">
          <h1 className="display-4">See Under the Surface</h1>
          <p className="lead text-muted">
            Tayos is on a mission to do more with sensors and data
          </p>
          <p className="leadLeftParagraph">
              The goal of Tayos is to marry deep understandings of physics, mathematics, and computer science together and deliver new 
              insights and capability in a friendly package for scientists and end users alike.  Our technology has applications in 
              medicine, agriculture, nutrition, civil engineering, and much more.  Even though we're just getting started, we have 
              big plans!
          </p>
          <Button variant="secondary" href="/contact/">Get in Touch</Button>
      </PlainModule>

      <Module right image={VaccineTubesImage} imageAlt="Vaccine manufacturing stock image.">
        <h2 className="display-4">Magnetic Induction Tomography</h2>
        <p className="lead text-muted">Working to stop mammogram false-positives</p>
        <p>
          According to the{" "}
          <a href="https://www.cancer.org/cancer/breast-cancer/screening-tests-and-early-detection/mammograms/limitations-of-mammograms.html"
            aria-label="Article on the limitations of Mammograms from the American Cancer Society">
            American Cancer Society
          </a>, mammograms miss one in eight breast cancers, and around <em>half</em> of women getting annual mammograms over a 
          10-year period will have a false-positive at some point.  Though nascent, single-coil Magnetic Induction Tomography (MIT) 
          presents a bright opportunity to do better for cheaper: cancerous tissues are fully seven times more conductive than healthy 
          tissue, and that's only the beginning.  Single-coil MIT could help detect and diagnose numerous conditions, and Tayos leads 
          the field in developing this technology.
        </p>
        <Button href="/papers/">See Research</Button>
      </Module>

      <Module image={TechnicianImage} imageAlt="Female electrician working on drone">
        <h2 className="display-4">We Are Tayos</h2>
        <p className="lead text-muted">A world-class team behind the tech</p>
        <p>
          The team at Tayos has world-class experience in cutting-edge physics, mathematics, electronics, and computer science.  We 
          bring together decades of diverse industry experiences ranging from soil science to operations research.  Together, our 
          senior leaders have well over a half-century of experience in a variety of industries and academia.  All 
          the more reason to let us help you apply our technology to your specific needs!
        </p>
      </Module>
    </PageLayout>
  )
}

export default AboutPage
